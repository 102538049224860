/*!* You can add global styles to this file, and also import other style files *!*/
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";
@import "../node_modules/ngx-ui-switch/ui-switch.component.css";
@import '../node_modules/jsoneditor/dist/jsoneditor.min.css';

:root {
  --color-default: #1f2328;
  --color-coral: #f85149;
  --border-color-light: #d8dee4;
  --button-background-color-light: #f8f9fa;
  --background-color-default: #fff;

  /* AML-2546: Вынес пока bs константы в root т.к у нас старая версия бутстрапа. Убрать при переходе на новую версию*/
  --bs-secondary-color: rgb(33 37 41 / 75%);
  --bs-btn-color: #6c6c6c;
  --bs-btn-bg: #f5f7f8;
  --bs-btn-border-color: #f5f7f8;
  --bs-btn-hover-color: #525252;
  --bs-btn-hover-bg: #e9ecef;
  --bs-btn-hover-border-color: #e9ecef;
  --bs-btn-active-color: var(--color-default);
  --bs-btn-active-bg: #dee2e6;
  --bs-btn-active-border-color: #dee2e6;
  --bs-btn-disabled-color: #6c6c6c;
  --bs-btn-disabled-bg: #f5f7f8;
  --bs-btn-disabled-border-color: #f5f7f8;
}

html, body {
  position: relative;
  min-height: 100%;
  height: 100%;
}

body {
  background-color: #EEEEEE;
}

.aml-color-red {
  color: #ae0000;
}

.aml-panel-white-br {
  padding: 10px;
  background-color: white;
  /*border-radius: 5px;*/
}
.aml-panel-white-form {
  height: 100%;
  padding-bottom: 60px
}
.aml-panel {
  padding: 0 10px;
}

.aml-panel-filter {
  background: #EEEEEE;
}

.aml-alert-mb-0 {
  margin-bottom: 0 !important;
}

.label-sm {
  margin-bottom: 5px;
}

.form-group-sm {
  margin-bottom: 10px;
}

.btn-black-border {
  border: 1px solid #4D4D4D !important;
}

.aml-header {
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  color: #4D4D4D;
}

.aml-header-sm {
  font-size: 20px;
}

.aml-filter label {
  font-size: 16px;
  line-height: 21px;
  color: #4D4D4D;
}
.aml-filter .aml-datepicker .form-control{
  margin-left: -9px !important;
}

.aml-panel-sticky-white {
  padding: 15px 0;
  background-color: white;
}

.pull-right {
  float: right;
}

.dropdown-menu {
  z-index: 1200;
}

ngb-datepicker.dropdown-menu {
  z-index: 1031;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: -10px;
  left: 0;
  z-index: 1030;
}

.top-modal-editor {
  z-index: 1250 !important;
}

.pagination {
  margin-bottom: 0;
}

.table {
  margin-bottom: 0;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.flex-paginator {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.flex-direction-column {
  display: flex;
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.cup {
  cursor: pointer;
}

.link {
  cursor: pointer;
  color: #007bff !important;
}

.link:hover {
  text-decoration: underline;
}

.aml-visit-link:visited {
  color: #8ab6f1;
}

.aml-border-2 {
  border-width: 2px !important;
}

.aml-mp-0 {
  padding: 0;
  margin: 0;
}

.loading-wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  background: rgba(234, 234, 234, 0.5);
  z-index: 1100;
}

.aml-sdk-form-filter-wrap .card-header {
  padding: 0.25rem 1.25rem;
}

.aml-sdk-form-filter-wrap .ng-select.ng-select-single .ng-select-container {
  height: calc(1.5em + 0.5rem);
  min-height: calc(1.5em + 0.5rem);
  line-height: 1.5;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ie-fixed-fixed-sticky {
    position: static !important;
  }
}

#spin_start-page_load_aml {
  position: absolute;
  height: 100px;
  width: 100px;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  background-size: 100%;
}

@keyframes check {
  0% {
    height: 0;
    width: 0;
  }
  25% {
    height: 0;
    width: 10px;
  }
  50% {
    height: 16px;
    width: 8px;
  }
}
.aml-panel .table td {
  font-size: 15px;
  padding: 0.5rem;
}

.aml-th-td-checkbox {
  padding-top: 6px !important;
  padding-bottom: 0 !important;
}

.aml-checkbox {
  background-color: #fff;
  display: inline-block;
  height: 16px;
  margin: 0 .25em;
  width: 16px;
  border-radius: 2px;
  border: 1px solid #ccc;
  /*float: right*/
}

.aml-checkbox span {
  display: block;
  height: 16px;
  position: relative;
  width: 16px;
  padding: 0
}

.aml-checkbox span:after {
  -moz-transform: scaleX(-1) rotate(135deg);
  -ms-transform: scaleX(-1) rotate(135deg);
  -webkit-transform: scaleX(-1) rotate(135deg);
  transform: scaleX(-1) rotate(135deg);
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  border-right: 3px solid #fff;
  border-top: 3px solid #fff;
  content: '';
  display: block;
  height: 12px;
  left: 0;
  position: absolute;
  top: 7px;
  width: 8px
}

.aml-checkbox span:hover:after {
  border-color: #999
}

.aml-checkbox input {
  display: none
}

.aml-checkbox input:checked + span:after {
  -webkit-animation: check .5s;
  -moz-animation: check .5s;
  -o-animation: check .5s;
  animation: check .5s;
  border-color: #555
}

.aml-checkbox input:checked + .default:after {
  border-color: #444
}

.aml-checkbox input:checked + .primary:after {
  border-color: #2196F3
}

.aml-checkbox input:checked + .success:after {
  border-color: #8bc34a
}

.aml-checkbox input:checked + .info:after {
  border-color: #3de0f5
}

.aml-checkbox input:checked + .warning:after {
  border-color: #FFC107
}

.aml-checkbox input:checked + .danger:after {
  border-color: #f44336
}

.disabled .aml-checkbox input:checked + .success:after {
  border-color: #999
}

.aml-checkbox-form {
  display: inline-block;
}

.aml-checkbox-form .aml-checkbox {
  float: left;
  margin-top: 4px;
  margin-right: 15px;
}

.aml-checkbox-form .aml-checkbox-text {
  float: left;
}

.aml-checkbox-form .aml-checkbox-text label {
  cursor: pointer;
}

.aml-rq-red {
  color: red;
}

.aml-filter-switch {
  display: flex;
  align-items: center;
  height: 31px;
  width: 35px;
}

.aml-filter-switch-block {
  padding: 0.4rem 0.5rem 0.25rem 0.5rem;
  margin-bottom: 0;
}

.aml-filter-switch-block label {
  margin-bottom: -3px
}

.aml-bg-header-color {
  background-color: #0D4D77;
}

.aml-text-color-white {
  color: white;
}

.aml-text-color-gray {
  color: gray;
}

.aml-cursor-def {
  cursor: default;
}

.aml-cursor-pointer {
  cursor: pointer;
}

.aml-alert-position {
  position: fixed;
  bottom: 0;
  left: 15px;
}

.aml-body-background-start-page_ {
  background: linear-gradient(to right, rgb(35, 37, 38), rgb(65, 67, 69));
}

.aml-body-background-start-page {
  width: 1440px;
  margin: 0px auto;
  background: url('./assets/svg/aml-fon.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #012d4a !important;
  background-position: 50% 50%;
}

.aml-header-sp header .aml-bg-header-color {
  background: linear-gradient(to right, rgb(39, 41, 42), rgb(91, 93, 95));
}

.aml-thead-table {
  background-color: #EEEEEE;
}

.aml-th-middle-text th {
  vertical-align: middle !important;
  user-select: none;
  font-weight: normal !important;
  font-size: 14px;
}

.aml-td-middle-text td {
  vertical-align: middle !important;
  cursor: pointer;
}

.aml-td-middle-text-default td {
  vertical-align: middle !important;
}

.aml-popover-history {
  max-width: max-content;
}

.aml-label-readonly-form label {
  color: #989b9d;
}

.aml-no-border input {
  outline: none;
  border: 0;
  box-shadow: none;
}

.aml-no-border app-datepicker input {
  border: 1px solid #ced4da;
}

.aml-hr-form {
  width: 50%;
  display: inline-block;
}

.aml-d-none {
  display: none;
}

.aml-popover-history-table {
  max-height: 500px;
  width: 600px;
  overflow-y: auto
}

.aml-i-first {
  font-size: 35em;
}

.aml-home-page p {
  font-size: 20pt
}

.aml-home-page button {
  font-size: 16pt;
  padding: 1.5rem !important
}

@media (max-width: 600px) {
  .aml-popover-history-table {
    max-height: 300px;
    width: 400px;
    overflow-y: auto
  }

  .aml-home-page .aml-i-first {
    font-size: 200pt;
  }

  .aml-home-page p {
    font-size: 10pt
  }

  .aml-home-page button {
    font-size: 8pt;
    padding: 0.7rem !important
  }
}

.aml-accordion-control .card {
  border: 0;
  border-radius: 0
}

.aml-accordion-control .card-header {
  padding: 0;
  margin-bottom: 0;
  background-color: white;
  border-bottom: 0;
}

.aml-accordion-control .card-body {
  padding: 0;
}

.aml-accordion-control .card-body .form-group {
  padding: 0;
  margin: 0;
}

.aml-header-nav-panel a {
  border-radius: 0 !important;
}

.aml-header-nav {
  user-select: none;
  color: white;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  /*border-radius: 5px 5px 0 0;*/
}

.aml-form-group {
  margin-bottom: 0;
}

/* Свойство необходимо для исправления отображения датапикеров и селектов в аккордионах*/
.accordion .card {
  overflow: visible !important;
}


.table-comp {
  margin-top: 5px;
}

.alert-panel .alert {
  margin-bottom: 0 !important;
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}

.alert-panel .alert .close {
  padding-top: 0.2em !important;
}

/*Переопределения для Досье*/
.aml-dossier .card {
  border: none;
  border-radius: 0 !important;
}

.aml-dossier .card-body {
  padding: 5px 0 0 0;
}

.aml-dossier .card-header {
  background: white;
  padding: 0;
  margin: 0;
}

.aml-dossier .custom-select-field .ng-select-container {
  height: calc(1.5em + 0.5rem + 2px) !important;
  min-height: calc(1.5em + 0.5rem + 2px) !important;
  font-size: 0.875rem !important;
}

.aml-dossier .custom-select-field.ng-select-multiple .ng-select-container {
  height: auto !important;
}

.aml-dossier .custom-select-field .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 5px 10px !important;
}

.custom-select-sm .custom-select-field .ng-select-container {
  height: calc(1.5em + 0.5rem + 2px) !important;
  min-height: calc(1.5em + 0.5rem + 2px) !important;
  font-size: 0.875rem !important;
}

.custom-select-sm .custom-select-field.ng-select-multiple .ng-select-container {
  height: auto !important;
}

.custom-select-sm .custom-select-field .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 5px 10px !important;
}

.aml-dossier .fias-select .ng-select-container {
  height: calc(1.5em + 0.5rem + 2px) !important;
  min-height: calc(1.5em + 0.5rem + 2px) !important;
  font-size: 0.875rem !important;
}

.aml-dossier .fias-select.ng-select-multiple .ng-select-container {
  height: auto !important;
}

.ng-select-sm .ng-select-container {
  height: calc(1.5em + 0.5rem + 2px) !important;
  min-height: calc(1.5em + 0.5rem + 2px) !important;
  font-size: 0.875rem !important;
}

.ng-select-sm.ng-select-multiple .ng-select-container {
  height: auto !important;
}

.aml-dossier .fias-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 5px 10px !important;
}

.aml-dossier .app-valid {
  padding: 0 0 0 0.5rem !important;
}

.app-valid:empty {
  display: none;
}

.aml-dossier .table-comp .form-group {
  margin-bottom: 5px !important;
}

.aml-dossier .table-comp .table th, .aml-dossier .table-comp .table td {
  padding: 0.5rem !important;
  font-size: 15px;
}

.aml-dossier .table-comp .table td {
  cursor: pointer;
}

.aml-dossier label {
  margin-bottom: 0 !important;
}

.aml-dossier .form-group {
  margin-bottom: 0.5rem;
}

.aml-dossier .alert-panel .alert {
  margin-bottom: 0 !important;
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}

.aml-dossier-bottom-menu .alert {
  margin-bottom: 0 !important;
  padding-top: 0.45rem !important;
  padding-bottom: 0.45rem !important;
}

.aml-dossier-bottom-menu .alert .close {
  padding-top: 0.25em !important;
  padding-bottom: 0.25em !important;
}

.aml-dossier-root .alert {
  margin-bottom: 0 !important;
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}

.aml-dossier-root .alert .close {
  margin-top: -1px !important;
  margin-left: 5px;
}

.aml-dossier app-alert-panel .alert .close {
  margin-top: -0.9px !important;
  padding-top: 0.2em !important;
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #e9ecef !important;
}

.ng-select.ng-select-disabled .ng-arrow-wrapper {
  display: none;
}

.aml-dossier-informant-panel {
  float: left;
}

.aml-dossier-main .nav .active {
  background: white !important;
  border: 2px solid #99caff7a !important;
  color: #007bff !important;
}

.app-valid .card-body, .app-valid .card-header {
  background-color: #f8d7da;
}

.aml-tooltip{
  z-index: 1500 !important;
}

.aml-tooltip .tooltip-inner {
  text-align: left;
  max-width: 400px;
  background: #721c24e0 !important;
  padding: 10px;
}

.aml-tooltip .arrow::before {
  border: 0;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .highlighted {
  font-weight: 500 !important;
}

.aml-highlighted-decorator {
  text-decoration: underline;
  font-weight: 400 !important;
}

.font-w-500 {
  font-weight: 500 !important;
}

.aml-el-toggle {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  -moz-transition: opacity .3s, max-height .3s;
  -ms-transition: opacity .3s, max-height .3s;
  transition: opacity .3s, max-height .3s;
  _display: none;
}

.aml-el-expanded {
  max-height: 100%;
  opacity: 1;
  _display: block;
}

.sub-td {
  width: 50px;
}

.jsoneditor-menu a.jsoneditor-poweredBy {
  color: transparent;
}

[hidden] {
  display: none !important;
}

.btn.disabled, .btn:disabled {
  opacity: 0.30 !important;
}

.big-select.is-read-only .ng-select.ng-select-disabled > .ng-select-container {
  background: white !important;
}

.text-line-through {
  text-decoration: line-through;
}

.aml-dossier-root {
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  height: 100%;
}

.aml-dossier-main {
  height: 100%
}

.aml-dossier-form {
  height: 100%;
}

.aml-dossier-block {
  height: 100%;
}

.aml-dossier-menu {
  background: white !important;
}

.aml-dossier-bottom-menu {
  margin: 0 15px;
}

/*TODO в будущем необходимо будет переписать селекторы и вынест их в файлы стилей компонент*/
.aml-dossier-container > app-client-check-clients > .flex-paginator > .ie-fixed-fixed-sticky {
  bottom: 50px;
}

.aml-dossier-container > app-clients-transactions > .flex-paginator > .ie-fixed-fixed-sticky {
  bottom: 50px;
  margin-right: -11px;
}

.aml-dossier-container > app-check-transactions > .flex-paginator > .ie-fixed-fixed-sticky {
  bottom: 50px;
}

.aml-visited {
  background: #007bff1f;
}

.aml-visited.btn-link {
  color: #8ab6f1 !important;
}

.aml-dashed-span {
  border-bottom: 1px dashed #007bff;
  color: #007bff;
  cursor: pointer;
}

app-admin-settings .custom-tooltip .tooltip-inner {
  background-color: white;
  color: black;
  border: solid 1px silver;
  font-size: 100%;
  max-width: 300px;
}

app-admin-settings .custom-tooltip .arrow::before {
  border-top-color: #ffffff;
}

.th_td-checkbox {
  border-right: 1px solid lightgray;
}

ngb-datepicker {
  z-index: 1051 !important;
}

.custom-mb-6 {
  margin-bottom: 4rem !important;
}

.has-alert-resolve {
  color: red;
}

.element-align-right {
  float: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.thumbtack-unpinned {
  color: black;
  transform: rotate(90deg);
  cursor: pointer;
}

.thumbtack-pinned {
  color: black;
  cursor: pointer;
}

.thumbtack-pinned.white-thumbtack {
  color: white;
}

.thumbtack-unpinned.white-thumbtack {
  color: white;
}

.aml-menu-nav-title {
  font-weight: 400px;
  font-size: 16px;
}

.aml-font-w-500 {
  font-weight: 500;
}

#dropdownMenuNav.dropdown-toggle::after {
  display: none !important;
}

.aml-header-nav-pinned {
  padding: 5px;
  padding-right: 17px;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid white;
}

.aml-header-dropdown-pinned {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}

.aml-width-300 {
  width: 300px;
}

.aml-caret-up {
  transform: rotate(180deg);
  display: inline-block;
  margin-top: 3px;
  position: absolute;
  margin-left: 3px;
}

.aml-caret-down {
  display: inline-block;
  margin-top: 1px;
  position: absolute;
  margin-left: 3px;
}

.customValidator-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customValidator-text {
  padding: 5px;
}

.customValidator-icon {
  padding-right: 10px;
}

.dropdown-arrow_hide.dropdown-toggle::after {
  display: none;
}

.profile-btn {
  color: #0D4D77;
  background: white;
  width: 38px;
  height: 38px;
  border-radius: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  margin-left: 30px;
}

.profile-btn:hover {
  color: #0D4D77;
  background: #e0e0e0;
}

.modal-full {
  width: 100%;
  height: 100%;
  min-width: 100%;
  max-height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0 15px;

}
.modal-full .modal-content {
  height: 100%;
  min-height: 100%;
}
.modal-hide-opacity {
  opacity: 0 !important;
}

.buttons-panel {
  background-color: #fff;
  position: sticky;
  top: -10px;
  z-index: 1;
}

a .without-href{
  color: #007bff;
  text-decoration: none;
}
a .without-href:hover {
  color: #0056b3;
  text-decoration: underline;
}

/* AML-2546 кастом для Селекта при is-invalid */
.ng-select.ng-select-is-invalid > .ng-select-container {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

.close-icon {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close-icon:hover {
  color:#000000;
  text-decoration:none;
  opacity:0.75;
  filter:alpha(opacity=40);
  }
